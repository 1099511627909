import React, { useCallback, type ReactNode } from 'react';
import EditFilledIcon from '@atlaskit/icon/core/migration/edit--edit-filled';
import { InvitePeopleModalContextProvider } from '@atlassian/invite-people/InvitePeopleModalContext';
import {
	ROUTE_NAMES_SYSTEM_DASHBOARD_EDIT,
	ROUTE_NAMES_DASHBOARD_EDIT,
} from '@atlassian/jira-common-constants/src/spa-routes';
import {
	DASHBOARD_VIEW_SOURCENAME,
	type DashboardConfigType,
} from '@atlassian/jira-dashboard-common';
import {
	ActionDropdown,
	ConnectHeader,
	Header,
	HeaderTitle,
	HeaderActions,
	useConnectMenuItems,
	DashboardRefreshButton,
	FavoriteButton,
	RouteLinkButton,
	useDashboardAnalytics,
	AddPeopleButton,
} from '@atlassian/jira-dashboard-internal-common';
import { RouteLinkButtonOld } from '@atlassian/jira-dashboard-internal-common/src/ui/route-link-button';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { useRouteByName } from '@atlassian/jira-platform-router-utils';
import { UI_EVENT_TYPE } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { HeaderCopyLinkButton } from './header-copy-link-button';
import messages from './messages';

export type HeaderReadOnlyProps = {
	isEditLinkEnabled: boolean;
	data: DashboardConfigType;
	banners: ReactNode;
};

export const HeaderReadOnly = ({ data, isEditLinkEnabled, banners }: HeaderReadOnlyProps) => {
	const tenantContext = useTenantContext();
	const { isAnonymous } = tenantContext;
	const { id, title, systemDashboard } = data;
	const { headerItems, toolItems } = useConnectMenuItems(id);
	const { formatMessage } = useIntl();
	const editRoute = useRouteByName(
		systemDashboard ? ROUTE_NAMES_SYSTEM_DASHBOARD_EDIT : ROUTE_NAMES_DASHBOARD_EDIT,
	);

	const fireAnalytics = useDashboardAnalytics();

	const onClickHandler = useCallback(() => {
		fireAnalytics({
			eventType: UI_EVENT_TYPE,
			action: 'clicked',
			actionSubject: 'dashboard.edit-button',
			actionSubjectId: DASHBOARD_VIEW_SOURCENAME,
		});
	}, [fireAnalytics]);

	return (
		<Header banners={banners}>
			<HeaderTitle>{title}</HeaderTitle>
			<HeaderActions>
				<ConnectHeader items={headerItems} />
				{!isAnonymous && <FavoriteButton dashboardId={id} dashboardName={title} />}
				<HeaderCopyLinkButton dashboardId={id} />
				{expValEquals(
					'jsw_peu_expansion_add_people_button_dashboard_view',
					'cohort',
					'variation',
				) && (
					<InvitePeopleModalContextProvider>
						<AddPeopleButton dashboardId={id} />
					</InvitePeopleModalContextProvider>
				)}
				<DashboardRefreshButton />
				{isEditLinkEnabled &&
					(editRoute && isVisualRefreshEnabled() ? (
						<RouteLinkButton
							to={editRoute}
							params={{ dashboardId: id }}
							onClick={onClickHandler}
							testId="dashboard-view.ui.header-readonly.dashboard-edit-button"
							iconAfter={EditFilledIcon}
						>
							{formatMessage(messages.edit)}
						</RouteLinkButton>
					) : (
						<RouteLinkButtonOld
							to={editRoute ?? undefined}
							params={{ dashboardId: id }}
							prefetch="hover"
							onClick={onClickHandler}
							testId="dashboard-view.ui.header-readonly.dashboard-edit-button"
							iconAfter={<EditFilledIcon label="" LEGACY_size="small" color="currentColor" />}
						>
							{formatMessage(messages.edit)}
						</RouteLinkButtonOld>
					))}
				<ActionDropdown data={data} connectToolMenuItems={toolItems} />
			</HeaderActions>
		</Header>
	);
};
