import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Blanket from '@atlaskit/blanket';
import sendExperienceAnalytics from '@atlassian/jira-common-experience-tracking-analytics';
import {
	MODAL_ID,
	DASHBOARD_AUTOMATIC_REFRESH_EXPERIENCE,
	DASHBOARD_AUTOMATIC_REFRESH,
} from '@atlassian/jira-dashboard-common';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller';
import { handleFetchErrorAsExperience } from '../../../../../../utils';
import { logErrorEventFunc } from '../utils';
import type DashboardAutomaticRefreshModal from './main';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyDashboardModal = lazy<typeof DashboardAutomaticRefreshModal>(
	() => import(/* webpackChunkName: "async-dashboard-automatic-refresh-modal" */ './main'),
);

const expAttributes = {
	analyticsSource: 'dashboard',
	experience: DASHBOARD_AUTOMATIC_REFRESH_EXPERIENCE,
	application: null,
	edition: null,
	additionalAttributes: {
		isCancel: false,
	},
} as const;

const sendSuccessExperience = () =>
	sendExperienceAnalytics({
		...expAttributes,
		wasExperienceSuccesful: true,
	});

const sendFailedExperience = () =>
	sendExperienceAnalytics({
		...expAttributes,
		wasExperienceSuccesful: false,
	});

export const AutomaticRefreshModal = ({
	id,
	onModalCloseCallback,
}: {
	id: string;
	onModalCloseCallback?: () => void;
}) => {
	const [isOpen, { off: close }] = useSwitchModals(MODAL_ID.AUTOMATIC_REFRESH);

	const onFailed = (err?: Error) => {
		const error = err || new Error('automatic refresh modal configuration failed.');
		handleFetchErrorAsExperience({
			error,
			sendSuccessExperience,
			sendFailedExperience,
			logErrorEvent: logErrorEventFunc(error, DASHBOARD_AUTOMATIC_REFRESH),
		});
		close();
		onModalCloseCallback?.();
	};

	const onCancel = () => {
		sendExperienceAnalytics({
			...expAttributes,
			wasExperienceSuccesful: true,
			additionalAttributes: {
				isCancel: true,
			},
		});
		close();
		onModalCloseCallback?.();
	};

	const onClose = () => {
		close();
		onModalCloseCallback?.();
	};

	return isOpen ? (
		<JSErrorBoundary
			id={DASHBOARD_AUTOMATIC_REFRESH_EXPERIENCE}
			packageName="dashboard"
			fallback="flag"
			attributes={{ task: DASHBOARD_AUTOMATIC_REFRESH_EXPERIENCE }}
			withExperienceTracker
		>
			<Placeholder name="dashboard-modal" fallback={<Blanket isTinted />}>
				<LazyDashboardModal
					id={id}
					onClose={onClose}
					onSuccess={sendSuccessExperience}
					onCancel={onCancel}
					onFailed={onFailed}
				/>
			</Placeholder>
		</JSErrorBoundary>
	) : null;
};
