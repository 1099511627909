import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	copied: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'common.field-copy-text.copied',
		defaultMessage: 'Copied!',
		description: 'Tooltip shown when text has been copied',
	},

	copyFailed: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'common.field-copy-text.copy-failed',
		defaultMessage: "Couldn't copy to clipboard",
		description: 'Tooltip shown when text failed to be copied',
	},
});
