import React, { Component } from 'react';
import { getFavorite } from '@atlassian/jira-favourite-button/src/rest/get-favorite.tsx';
import { FavoriteButton, type OwnProps } from '@atlassian/jira-favourite-button/src/view/view.tsx';
import { FavoriteChangeConsumer } from '@atlassian/jira-favourite-change-provider/src/view/index.tsx';

// eslint-disable-next-line jira/react/no-class-components
export default class FavoriteButtonWrapper extends Component<OwnProps> {
	render() {
		return (
			<FavoriteChangeConsumer>
				{(favoriteChangeContext) => (
					<FavoriteButton {...this.props} favoriteChangeContext={favoriteChangeContext} />
				)}
			</FavoriteChangeConsumer>
		);
	}
}

export { getFavorite };
