import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import logger from '@atlassian/jira-common-util-logging/src/log';
import {
	GADGET_METRICS_TYPE,
	type GadgetData,
	type GadgetMetricsCallback,
	type DashboardMessageListener,
} from '@atlassian/jira-dashboard-common';
import { fg } from '@atlassian/jira-feature-gating';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller';
import { toColumns } from '../../utils/layout';
import { useRenderAboveTheFold } from '../above-the-fold';
import { getAboveTheFoldGadgets } from '../above-the-fold/main';
import { useMaximizedGadget } from '../maximized-gadget';
import { useMessageBus } from '../message-bus';
import {
	CONNECT_MODULE_PATTERN,
	GADGET_MARK_EVENT_PREFIX,
	IDLE_GADGET_SOURCE,
	WRM_GADGET_METRICS,
} from './constants';
import type {
	GadgetId,
	EventDataType,
	GadgetLifeCycleRecord,
	GadgetMetricsEventsMap,
	GadgetMetricsRecord,
	MetricsBridgeContext,
} from './gadget-metrics-bridge-types';
import {
	getAllEventHandlers,
	useGadgetMetricsAnalytics,
	getNavStart,
} from './gadget-metrics-event-analytics';
import { GADGETS_TO_REPORT, GADGETS_IGNORED } from './gadget-metrics-inclusion-list';

const OVERALL_TIMEOUT = 60000;

const buildEventKey = (event: EventDataType): string => {
	if (
		event.eventType !== GADGET_MARK_EVENT_PREFIX ||
		event.markName == null ||
		event.markStage == null
	) {
		return event.eventType;
	}
	return `${GADGET_MARK_EVENT_PREFIX}-${event.markName}-${event.markStage}`;
};

export const recordEvent = (
	gadgetMetricsEvents: GadgetMetricsEventsMap,
	gadgetId: string,
	event: EventDataType,
) => {
	let recorded = gadgetMetricsEvents.get(gadgetId);
	if (recorded == null) {
		recorded = {
			contentType: 'Unspecified',
			source: event.source,
			events: new Map(),
		};
		gadgetMetricsEvents.set(gadgetId, recorded);
	}
	if (recorded.contentType !== 'ErrorMessage' && event.contentType !== 'Unspecified') {
		recorded.contentType = event.contentType;
	}
	const record: GadgetMetricsRecord = {
		spaTime: Date.now(),
	};
	if (!recorded.events.has(buildEventKey(event))) {
		recorded.events.set(buildEventKey(event), record);
	}
};

export const extractGadgetIdsInTti = (gadgets: GadgetData[]): GadgetId[] =>
	gadgets
		.filter((item) => item.amdModule != null || item.forge != null || item.reactKey != null)
		// Include only gadgets in the inclusion list
		.filter(
			(item) =>
				// report all forge and react gadgets
				item.forge != null || item.reactKey != null || GADGETS_TO_REPORT.has(item.amdModule ?? ''),
		)
		.map((gadget) => gadget.id);

export const findImplicitlyIgnoredGadgets = (gadgets: GadgetData[]): GadgetData[] =>
	gadgets
		// Include only WRM gadget
		.filter((item) => item.amdModule != null)
		// exclude connect gadgets
		.filter((item) => !CONNECT_MODULE_PATTERN.test(item.amdModule || ''))
		// find gadgets not in either inclusion list or exclusion list
		.filter(
			(item) =>
				!GADGETS_TO_REPORT.has(item.amdModule ?? '') && !GADGETS_IGNORED.has(item.amdModule ?? ''),
		);

// Currently WRM gadget does not send render finish event in Config mode so we don't have accurate TTI for it
const shouldSendGadgetMetrics = (event: GadgetLifeCycleRecord): boolean =>
	!(event.source === WRM_GADGET_METRICS && event.contentType === 'Config');

export const useGadgetMetricsBridge = (
	dashboardId: string,
	gadgets: GadgetData[],
	layout: string,
) => {
	const currentDashboardId = useRef<string | null>(null);
	// Metrics context in the a dashboard lifecycle.
	const dashboardMetricsContext = useRef<MetricsBridgeContext | null>(null);
	const [
		,
		{
			registerListener: registerMessageListener,
			unregisterListener: unregisterMessageListener,
			broadcastMessage: broadcastMessageListener,
		},
	] = useMessageBus();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const {
		sendGadgetMetrics,
		sendOverallGadgetMetrics,
		onAboveTheFoldGadgetsRender,
		resetScrollStartMetrics,
	} = useGadgetMetricsAnalytics(dashboardId);

	const [, { onGadgetRender }] = useRenderAboveTheFold();

	const [{ isInitialRender, lastTransitionStartTime, navigationStart, currentPageId }] =
		useSpaStateTransition();

	const { name: routeName } = useCurrentRoute();

	const { getMaximisedGadgetId } = useMaximizedGadget(gadgets);
	const maximizedId = getMaximisedGadgetId;

	if (currentDashboardId.current == null || currentDashboardId.current !== dashboardId) {
		fireOperationalAnalytics(createAnalyticsEvent({}), 'dashboardMetricsContext renewed', {
			dashboardId,
			previousDashboardId: currentDashboardId.current,
			currentPageId,
		});
		resetScrollStartMetrics();
		// Dashboard reinitialised or changed
		currentDashboardId.current = dashboardId;
		if (dashboardMetricsContext.current?.metricsTimeout != null) {
			clearTimeout(dashboardMetricsContext.current.metricsTimeout);
		}

		const extractedGadgets = extractGadgetIdsInTti(
			maximizedId && fg('endeavour_dashboard_only_maximised_gadget_returned')
				? gadgets.filter((gadget) => gadget.id === maximizedId)
				: gadgets,
		);
		const allAboveTheFoldGadgets = getAboveTheFoldGadgets(toColumns(gadgets, layout), dashboardId);
		dashboardMetricsContext.current = {
			gadgetMetricsEvents: new Map(),
			gadgetIdsInOverallAnalytics: extractedGadgets,
			gadgetIdsInTti: extractedGadgets.filter((gadgetId) => allAboveTheFoldGadgets.has(gadgetId)),
			allGadgetIds: gadgets.map((item) => item.id),
			overallMetricsSent: false,
			atfMetricsSent: false,
			implicitlyIgnoredGadgetLogged: false,
			gadgetRenderStartTime: Date.now(),
			metricsTimeout: setTimeout(() => {
				broadcastMessageListener(GADGET_METRICS_TYPE.TIMEOUT, {}, {});
			}, OVERALL_TIMEOUT),
			timestamps: {
				created: Date.now(),
				pnow: performance?.now(),
				currentPageId,
				lastTransitionStartTime,
				navigationStart,
				navStart: getNavStart(isInitialRender, lastTransitionStartTime, navigationStart),
			},
		};
	}

	const gadgetMetricsTimeout = useCallback(() => {
		const context = dashboardMetricsContext.current;
		if (context == null) {
			return;
		}
		if (context.overallMetricsSent) {
			return;
		}
		context.metricsTimeout = undefined;
		// Set up the overall flag so that gadgetMetricsListener will stop handling gadget events
		context.overallMetricsSent = true;

		// Send metrics for timed out gadget that sent at least one event.
		context.gadgetMetricsEvents.forEach((item, id) => {
			if (item.allEventHandled !== true) {
				// eslint-disable-next-line no-param-reassign
				item.timedOut = true;
				sendGadgetMetrics(id, item);
			}
		});

		// Send metrics for idle gadgets that haven't sent any event
		context.gadgetIdsInOverallAnalytics
			.filter((id) => !context.gadgetMetricsEvents.has(id))
			.forEach((id) => {
				sendGadgetMetrics(id, {
					source: IDLE_GADGET_SOURCE,
					timedOut: true,
					events: new Map(),
				});
			});
		onAboveTheFoldGadgetsRender(
			context.gadgetIdsInOverallAnalytics,
			context.gadgetMetricsEvents,
			context.gadgetRenderStartTime,
			context.timestamps,
			true,
		);
		sendOverallGadgetMetrics(
			context.gadgetIdsInOverallAnalytics,
			context.gadgetMetricsEvents,
			true,
		);
	}, [sendGadgetMetrics, sendOverallGadgetMetrics, onAboveTheFoldGadgetsRender]);

	const onAllGadgetHandled = useCallback(() => {
		// Set up the overall flag so that gadgetMetricsListener will stop handling gadget events
		const context = dashboardMetricsContext.current;
		if (context == null) {
			return;
		}
		context.overallMetricsSent = true;
		if (context.metricsTimeout != null) {
			clearTimeout(context.metricsTimeout);
			context.metricsTimeout = undefined;
		}

		sendOverallGadgetMetrics(
			context.gadgetIdsInOverallAnalytics,
			context.gadgetMetricsEvents,
			false,
		);
	}, [sendOverallGadgetMetrics]);

	const onAboveTheFoldGadgetsHandled = useCallback(() => {
		const context = dashboardMetricsContext.current;
		if (context == null) {
			return;
		}
		onAboveTheFoldGadgetsRender(
			context.gadgetIdsInOverallAnalytics,
			context.gadgetMetricsEvents,
			context.gadgetRenderStartTime,
			context.timestamps,
			false,
		);

		context.atfMetricsSent = true;
	}, [onAboveTheFoldGadgetsRender]);

	const gadgetMetricsListener: GadgetMetricsCallback = useCallback(
		(gid, command, ...args) => {
			if (command !== 'metrics_event' || !args[0]) {
				return;
			}
			const context = dashboardMetricsContext.current;
			if (context == null) {
				return;
			}
			if (context.overallMetricsSent) {
				return;
			}

			const eventData: EventDataType = args[0];
			const eventsPerGadget = getAllEventHandlers(eventData.source, eventData.contentType);
			const { pageId: pageIdInEvent } = eventData;

			// If the gadget id for the event is not in the current gadgetIds list, it is from a stale gadget of the previous dashboard
			if (currentPageId !== pageIdInEvent || context.allGadgetIds.every((id) => id !== gid)) {
				logger.safeWarnWithoutCustomerData(
					'spa-apps.dashboard.gadget.metrics',
					`Page [${currentPageId ?? ''}] recieved staled gadget metrics event [${
						eventData.eventType
					}] from gadget [${gid}] in page [${pageIdInEvent ?? ''}]`,
				);
				return;
			}

			// Check if all expected events for a gadget are triggered and handled
			const isAllGadgetEventsHandled = (gadgetEvents?: GadgetLifeCycleRecord) =>
				gadgetEvents?.allEventHandled === true ||
				gadgetEvents?.timedOut === true ||
				eventsPerGadget.every((e) => gadgetEvents?.events.has(e));

			// Check if all gadgets has been handled
			const isAllGadgetHandled = (gadgetIds: string[]) =>
				gadgetIds.every((id) => context.gadgetMetricsEvents.get(id)?.allEventHandled);

			if (context.gadgetMetricsEvents.has(gid)) {
				if (isAllGadgetEventsHandled(context.gadgetMetricsEvents.get(gid))) {
					// gadget has been handled
					return;
				}
				if (context.gadgetMetricsEvents.get(gid)?.events.has(buildEventKey(eventData))) {
					// event has been handled
					return;
				}
			}

			recordEvent(context.gadgetMetricsEvents, gid, eventData);

			const event = context.gadgetMetricsEvents.get(gid);
			if (event != null && isAllGadgetEventsHandled(event)) {
				event.allEventHandled = true;
				if (shouldSendGadgetMetrics(event)) {
					onGadgetRender(gid);
					sendGadgetMetrics(gid, event);
				}

				// if all above the fold gadgets are handled,  send gadgets metrics
				if (context.atfMetricsSent === false) {
					if (isAllGadgetHandled(context.gadgetIdsInTti)) {
						onAboveTheFoldGadgetsHandled();
					}
				}

				// if all gadgets are handled, send overall metrics
				if (isAllGadgetHandled(context.gadgetIdsInOverallAnalytics)) {
					onAllGadgetHandled();
					if (context.atfMetricsSent === false) {
						onAboveTheFoldGadgetsHandled();
					}
				}
			}
		},
		[
			currentPageId,
			onAboveTheFoldGadgetsHandled,
			onAllGadgetHandled,
			sendGadgetMetrics,
			onGadgetRender,
		],
	);

	useEffect(
		() => () => {
			// NOTE - This is temporary until we fix metrics for wallboard
			if (routeName === 'dashboard-wallboard') {
				return;
			}

			if (dashboardMetricsContext.current?.metricsTimeout != null) {
				clearTimeout(dashboardMetricsContext.current.metricsTimeout);
				dashboardMetricsContext.current.metricsTimeout = undefined;
			}
		},
		[routeName],
	);

	const gadgetMetricsMessageBusListenerAdaptor: DashboardMessageListener = useCallback(
		(type, message, context) => {
			if (type !== GADGET_METRICS_TYPE.REPORT) {
				return;
			}
			const { gadgetId: gid, pageId } = context;
			if (gid == null) {
				return;
			}

			gadgetMetricsListener(gid, 'metrics_event', {
				...message,
				pageId,
			});
		},
		[gadgetMetricsListener],
	);

	const timeoutMessageBusListenerAdaptor: DashboardMessageListener = useCallback(
		(type) => {
			if (type !== GADGET_METRICS_TYPE.TIMEOUT) {
				return;
			}

			gadgetMetricsTimeout();
		},
		[gadgetMetricsTimeout],
	);

	useLayoutEffect(() => {
		// NOTE - This is temporary until we fix metrics for wallboard
		if (routeName === 'dashboard-wallboard') {
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			return () => {};
		}

		registerMessageListener(gadgetMetricsMessageBusListenerAdaptor);
		registerMessageListener(timeoutMessageBusListenerAdaptor);

		return () => {
			unregisterMessageListener(gadgetMetricsMessageBusListenerAdaptor);
			unregisterMessageListener(timeoutMessageBusListenerAdaptor);
		};
	}, [
		gadgetMetricsMessageBusListenerAdaptor,
		gadgetMetricsListener,
		registerMessageListener,
		timeoutMessageBusListenerAdaptor,
		unregisterMessageListener,
		routeName,
	]);

	// NOTE - This is temporary until we fix metrics for wallboard
	if (routeName === 'dashboard-wallboard') {
		return;
	}

	if (
		dashboardMetricsContext.current == null ||
		dashboardMetricsContext.current.overallMetricsSent === true
	) {
		return;
	}

	// Send overall event immediately for empty dashboard
	if (dashboardMetricsContext.current?.gadgetIdsInOverallAnalytics.length === 0) {
		onAboveTheFoldGadgetsHandled();
		onAllGadgetHandled();
	} else if (
		dashboardMetricsContext.current?.gadgetIdsInTti.length === 0 &&
		dashboardMetricsContext.current?.atfMetricsSent === false
	) {
		onAboveTheFoldGadgetsHandled();
	}

	if (
		dashboardMetricsContext.current != null &&
		!dashboardMetricsContext.current.implicitlyIgnoredGadgetLogged
	) {
		dashboardMetricsContext.current.implicitlyIgnoredGadgetLogged = true;
		findImplicitlyIgnoredGadgets(gadgets).forEach((item) => {
			logger.safeWarnWithoutCustomerData(
				'spa-apps.dashboard.gadget.metrics',
				`metrics for gadget [${item.amdModule ?? 'unknown'}] is ignored implicitly.`,
			);
		});
	}
};
