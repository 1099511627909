import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types';
import type { SubProduct } from '@atlassian/jira-common-constants/src/sub-product-types';
import { ff } from '@atlassian/jira-feature-flagging';
import { subProductFromProjectType } from '@atlassian/jira-subproduct';

export const getSubProductFromProjectType = (projectType: ProjectType): SubProduct => {
	// for Analytics only 'product_discovery' is mapped to 'polaris'. In other cases it is mapped to 'software'
	if (ff('polaris-detect-subproduct-for-jpd-by-project-type_8jxfy', false)) {
		if (projectType === 'product_discovery') {
			return 'polaris';
		}

		return subProductFromProjectType(projectType);
	}
	return subProductFromProjectType(projectType);
};
