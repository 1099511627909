import React from 'react';
import { keyframes, styled } from '@compiled/react';
import MediaServicesPresentationIcon from '@atlaskit/icon/glyph/media-services/presentation';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { PAGE_LAYOUT_OFFSET_TOP } from '@atlassian/jira-common-constants/src/page-layout';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { DASHBOARD_HEADER_HEIGHT } from '@atlassian/jira-dashboard-common';
import { useIntl } from '@atlassian/jira-intl';
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import { getLocalStorageHeight } from '../../../utils';
import messages from './messages';
import type { SkeletonGadgetContentProps } from './types';

export const SkeletonGadgetContent = ({
	dashboardId,
	gadgetId,
	customHeight,
	isPulsing = true,
	isLimitedToScreenHeight = false,
}: SkeletonGadgetContentProps) => {
	const { formatMessage } = useIntl();
	const gadgetLocalHeight = customHeight ?? `${getLocalStorageHeight(dashboardId, gadgetId)}px`;
	const heightIsZero = gadgetLocalHeight.match(/^0[^.0-9]*$/); // will match 0px,0%,etc but not 0.1px

	return (
		<UFOLoadHold name="skeleton-gadget-content">
			<SkeletonContent
				height={gadgetLocalHeight}
				isLimitedToScreenHeight={isLimitedToScreenHeight}
				isPulsing={isPulsing}
				data-testid="dashboard-internal-common.common.gadget.gadget-skeleton.skeleton-content"
				role="status"
				aria-label="Loading"
			>
				{!heightIsZero && (
					// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
					<MediaServicesPresentationIcon
						size="large"
						label={formatMessage(messages.genericPlaceholderIcon)}
					/>
				)}
			</SkeletonContent>
		</UFOLoadHold>
	);
};

const PulseAnimation = keyframes({
	to: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text', colors.N30),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonContent = styled.div<{
	height: string;
	isLimitedToScreenHeight: boolean;
	isPulsing: boolean;
}>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: ({ height }) => height ?? '100%',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isLimitedToScreenHeight }) =>
		isLimitedToScreenHeight
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`max-height: calc(100vh - ${PAGE_LAYOUT_OFFSET_TOP} - ${DASHBOARD_HEADER_HEIGHT}px);`
			: undefined,
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral', colors.N20),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${gridSize / 2}px`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		svg: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			color: token('color.background.neutral', colors.N70),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			animationDuration: ({ isPulsing }) => (isPulsing ? '1s' : 0),
			animationDirection: 'alternate',
			animationIterationCount: 'infinite',
			animationName: PulseAnimation,
			animationTimingFunction: 'linear',
		},
	},
);
