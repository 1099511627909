import type {
	DashboardMessage,
	DashboardMessageContext,
	DashboardMessageListener,
	GadgetMetricType,
} from '@atlassian/jira-dashboard-common';
import { createHook, createStore, type Action } from '@atlassian/react-sweet-state';

type State = {
	listeners: DashboardMessageListener[];
};

const actions = {
	registerListener:
		(listener: DashboardMessageListener): Action<State> =>
		({ setState, getState }) => {
			const { listeners } = getState();

			setState({
				listeners: [...listeners, listener],
			});
		},

	unregisterListener:
		(listener: DashboardMessageListener): Action<State> =>
		({ setState, getState }) => {
			const { listeners } = getState();

			setState({
				listeners: listeners.filter((cb) => cb !== listener),
			});
		},

	broadcastMessage:
		(
			type: GadgetMetricType,
			message: DashboardMessage,
			context: DashboardMessageContext,
		): Action<State> =>
		({ getState }) => {
			getState().listeners.forEach((listener) => listener(type, message, context));
		},
} as const;

type Actions = typeof actions;

const Store = createStore<State, Actions>({
	name: 'dashboard-message-bus',
	initialState: {
		listeners: [],
	},
	actions,
});

// TODO this should be { selector: null } to ensure it is just actions
export const useMessageBus = createHook(Store);
