import React, { useMemo, type ReactNode } from 'react';
import type { GadgetData } from '@atlassian/jira-dashboard-common';
import { RenderAboveTheFoldContainer } from '../../../controllers/above-the-fold';
import { useMaximizedGadget } from '../../../controllers/maximized-gadget';
import { toColumns } from '../../../utils';

type AboveTheFoldContainerProps = {
	gadgets: GadgetData[];
	dashboardId: string;
	layout: string;
	children: ReactNode;
};

export const AboveTheFoldContainer = ({
	gadgets,
	dashboardId,
	layout,
	children,
}: AboveTheFoldContainerProps) => {
	const { getMaximisedGadgetId } = useMaximizedGadget(gadgets);
	const columns = useMemo(() => toColumns(gadgets, layout), [gadgets, layout]);

	return (
		<RenderAboveTheFoldContainer
			columns={columns}
			dashboardId={dashboardId}
			scope={dashboardId}
			maximizedGadgetId={getMaximisedGadgetId}
		>
			{children}
		</RenderAboveTheFoldContainer>
	);
};
