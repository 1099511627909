import React from 'react';
import FavoriteButtonInner from '@atlassian/jira-favourite-button';
import { FavoriteButtonWrapper } from '@atlassian/jira-favourite-button-stateless';
import { DASHBOARDS_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider';
import type { FavoriteButtonProps } from './types';

export const FavoriteButton = ({ dashboardId, dashboardName }: FavoriteButtonProps) => (
	<FavoriteButtonWrapper>
		<FavoriteButtonInner
			baseUrl=""
			itemId={dashboardId}
			itemType={DASHBOARDS_ITEM_TYPE}
			favoriteItemName={dashboardName}
		/>
	</FavoriteButtonWrapper>
);
